import './roseday.css';
const RoseDay = () => {
    return ( 
        <div className="roseday">
<div className="letter" style={{ padding: '20px' }}>
                <h1>Happy Rose Day Cutuuu!!</h1>
                <p>Aghhh... I really wished we could have spent our first Valentine week together.</p>
				<p>I will try to make up for it In my way 😄</p>
				<h1>Love you Janeman!</h1>
				<p>Here's something for you:</p>
				<p>na jane kyu yeh duniya peeche padi hai gubaal ke,</p>
				<p>tumari khubsurati ke saamne kha jaye ye maath</p>
				<p>kuch nahi yeh phul tumhare saamne,</p>
				<p>tumhari khubsurati badhti jo hai samay ke saath!</p>
            </div>
            <div className="rose">
				<div className="flower">
					<div className="petal"></div>
					<div className="petal"></div>
					<div className="petal"></div>
					<div className="petal"></div>
					<div className="petal"></div>
				</div>
				<div className="leaf">
					<div className="stem"></div>
					<div className="leafs"></div>
					<div className="leafs"></div>
				</div>
			</div>
        </div>
     );
}
 
export default RoseDay;
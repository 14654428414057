import './proposeday.css'
import { useState, useEffect } from 'react';
import song from './song.mp3';

let currentPromptindex = 0;

const ProposeDay = () => {
    let prompts = [
        "Toh na Jii!",
        "Mein na bolra tha na!",
        "Haan matlab aise hi!",
        "Meko khayal aya thaa!",
        "Bahut dinn se sochra tha!",
        "Ki na bol du!",
        "Tohh finally mein keh raha thaa!",
        "Ki na I Love you Jii!!",
        "Tohh naa!",
    ]

    let reply = [  
        "Acha jii Bolo!",
        "Haan Bolo!",
        "Kya Aise hii!",
        "Acha teko khayal bhi aata hai",
        "Kya sochra bhaii",
        "Bolega ki mein jaau?",
        "Dael na maar jaldi boll!!",
        "Acha jii hayeeee!!",
        "Haan jii!"
    ]

    let gifs = [
        "https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExcDdtZ2JiZDR0a3lvMWF4OG8yc3p6Ymdvd3g2d245amdveDhyYmx6eCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/cLS1cfxvGOPVpf9g3y/giphy.gif",
        "https://media.giphy.com/media/kDU6J7jVcMXs4m4ztt/giphy.gif",
        "https://media.giphy.com/media/lPM06f2zvk94jBAzlP/giphy.gif?cid=790b7611ej5vjet2b9jf5gf829mw9thpqmkqk3eq4324p0ah&ep=v1_stickers_search&rid=giphy.gif",
        "https://media.giphy.com/media/T86i6yDyOYz7J6dPhf/giphy.gif?cid=ecf05e47o8t348fg3dt9t61atbbo7dv6phdzed75ux3a7hzo&ep=v1_stickers_search&rid=giphy.gif"
    ]

    const [currentPrompt, setCurrentPrompt] = useState(prompts[0]);
    const [currentOption, setCurrentOption] = useState(reply[0]);
    const [isQuestion, setIsQuestion] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const [gif, setGif] = useState(gifs[0]);
    

    function nextPrompt() {
        console.log(currentPromptindex);
        currentPromptindex ++;
        if (currentPromptindex === prompts.length) {
            setIsQuestion(true);
            setGif(gifs[1]);
            return;
        }
        setCurrentPrompt(prompts[currentPromptindex]);
        setCurrentOption(reply[currentPromptindex]);
    }
    
    function accepted() {
        setIsAccepted(true);
        setGif(gifs[3]);
    }

    function moveButton() {
        var x = Math.random() * 50;//(window.innerWidth - document.getElementById('noButton').offsetWidth) - 85;
        var y = Math.random() * 50;//(window.innerHeight - document.getElementById('noButton').offsetHeight) - 48;
        document.getElementById('noButton').style.left = `${x}%`;
        document.getElementById('noButton').style.top = `${y}%`;
        setGif(gifs[2]);
    }
    return ( 
        <div className="container">
            <audio id="audio" src={song} autoPlay loop></audio>
            <div>
                {isAccepted ? (
                    <><h1 className="header_text">Ayeeee Hayeeee dekho tohhh ❤️</h1></>
                ) : (
                    <>
                    {isQuestion ? (
                        <h1 className="header_text">Will you be my Girlfriend? ☺️</h1>
                    ) : (
                        <h1 className="header_text">{currentPrompt}</h1>
                    )}
                    </>
                )
                }
                
            </div>
            <div className="gif_container">
                <img src={gif} alt="Cute animated illustration" />
            </div>
            <div className="buttons">
                {!isAccepted ? (
                    <>
                    {isQuestion ? (
                        <>                        
                        <button className="btn" id="yesButton" onClick={accepted}>Yes</button>
                        <button className="btn" id="noButton" onMouseOver={moveButton} onClick={moveButton}>Jaa Naa</button>
                        </>
                    ) : (
                        <button className="btn" id="nextButton" onClick={nextPrompt}>{currentOption}</button>    
                    )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            </div>
    );
}        


export default ProposeDay;

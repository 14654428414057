import teddy from '../../media/photos/teddy.png'
const Dot = (props) => {
    const {color, x, y, size, index, onClick} = props;    
    const dotStyle = {
        height: `${size}px`,
        width: `${size}px`,
        left: `${x}px`,
        top: `${y}px`,
    };
return (
        <img src={teddy} className="dot"
            style={dotStyle}
            onClick={() => onClick(index)} />
    );
};
export default Dot;
import chocodrip from '../../media/photos/chocodrip.png'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import heartblush from '../../media/photos/heartblush.jpg'
import fruitandnut from '../../media/photos/fruitandnut.jpg'
import ferrero from '../../media/photos/ferrero.jpg'
import './chocolateday.css'

const items = [
  {
    url: fruitandnut,
    src: 'https://www.swiggy.com/instamart/item/MTU6WICRTC?storeId=1387705',
    title: 'Dairy Milk Fruit & Nut',
    width: '40%',
  },
  {
    url: heartblush,
    src: 'https://www.swiggy.com/instamart/item/YW7OYT0QBE?custom_back=false&storeId=1387705',
    title: 'Silk Heart ❤️ Blush',
    width: '30%',
  },
  {
    url: ferrero,
    src: 'https://www.swiggy.com/instamart/item/1H3SANJGNC?storeId=1387705',
    title: 'Ferrero Rocher',
    width: '30%',
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));
  
  const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  });
  
  const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  }));
  
  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  }));
  
  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));
  

const ChocolateDay = () => {
    const handleImageClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="chocolateday">
            {/* <img src={chocodrip} style={{ width: '100%' }} /> */}
            <div className="chocolatewish">
                <h1>Happy Chocolate Day My Cutuu...!! I know tu diet par hai but pleaseeeee click karnaa!!</h1>
            </div>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%', mx: 'auto' }}>
                {items.map((image) => (
                    <ImageButton
                        focusRipple
                        key={image.title}
                        style={{
                            width: image.width,
                        }}
                        onClick={() => handleImageClick(image.src)} // Add onClick event handler
                    >
                        <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
                        <ImageBackdrop className="MuiImageBackdrop-root" />
                        <Image>
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                sx={{
                                    position: 'relative',
                                    p: 4,
                                    pt: 2,
                                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                }}
                            >
                                {image.title}
                                <ImageMarked className="MuiImageMarked-root" />
                            </Typography>
                        </Image>
                    </ImageButton>
                ))}
            </Box>
        </div>
    );
};
 
export default ChocolateDay;
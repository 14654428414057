import { getDocs, collection, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import { alpha } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { Timestamp } from 'firebase/firestore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';

const Shayari = () => {
    const [newShayariBySophia, setNewShayariBySophia] = useState('');
    const [newShayariByVarun, setNewShayariByVarun] = useState('');
    const shayariCollectionRef = collection(db, "Shayari");
    
    const [shayaris, setShayaris] = useState([]);
    const [shayarisDone, setShayarisDone] = useState(false);

    const getShayaris = async () => {
        try {
            const data = (await getDocs(shayariCollectionRef)).docs.map((doc) => ({...doc.data(), id: doc.id}));
            setShayaris(data);
            setShayarisDone(true);
        } catch (error) {
            console.log(error);
        }
    }

    const addToList = async (event, by) => {
        if (by === "Sophia") {
            setNewShayariBySophia('');
        } else {
            setNewShayariByVarun('');
        }
        event.preventDefault();
        try {
            const newItem = {
                text: by === "Sophia" ? newShayariBySophia.replace('\n','\\n') : newShayariByVarun.replace('\n','\\n'),
                AddedOn: Timestamp.now(),
                By: by,
                Likes: 0
            };

            const docRef = await addDoc(shayariCollectionRef, newItem);
            const newItemWithId = { ...newItem, id: docRef.id };
            setShayaris([...shayaris, newItemWithId]);
        } catch (error) {
            console.log(error);
        }
    }

    const handleDelete = async (itemId) => {
        try {
            // Ask for confirmation
            const confirmed = window.confirm("Are you sure you want to delete this item?");

            if (confirmed) {
                // Delete the item locally
                const updatedShayaris = shayaris.filter(item => item.id !== itemId);
                setShayaris(updatedShayaris);

                // Delete the item in the database
                await deleteDoc(doc(shayariCollectionRef, itemId));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleLike = async (itemId) => {
        try {
            // Find the item in the array
            const itemIndex = shayaris.findIndex(item => item.id === itemId);
            if (itemIndex === -1) {
                return; // Item not found
            }

            // Increase the Likes property by 1
            const updatedShayaris = [...shayaris];
            updatedShayaris[itemIndex].Likes += 1;

            // Update the item locally
            setShayaris(updatedShayaris);

            // Update the item in the database
            await updateDoc(doc(shayariCollectionRef, itemId), {
                Likes: updatedShayaris[itemIndex].Likes
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getShayaris();
    }, []);

    return (
        <Fragment>
            {shayarisDone ? (
                <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ width: '50%'}}>
                                <Typography variant="h6" component="div" style={{ fontSize: '1.5rem' }}>
                                    SOPHIA
                                </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: '50%'}}>
                                <Typography variant="h6" component="div" style={{ fontSize: '1.5rem' }}>
                                    VARUN
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    {/* Form for adding a new item */}
                                    <Card variant="outlined" sx={{ backgroundColor: alpha('#c7c7c7', 0.4), boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', width: 'fit-content', margin: '8px' }}>
                                        <CardContent style={{ overflowWrap: 'break-word' }}>
                                            <form onSubmit={(event)=>addToList(event, "Sophia")}>
                                                <textarea 
                                                    type="text"
                                                    placeholder="Shayari"
                                                    onChange={event => setNewShayariBySophia(event.target.value)}
                                                    value={newShayariBySophia}
                                                />
                                                <Button type="submit">Add</Button>
                                            </form>
                                        </CardContent>
                                    </Card>                                    
                                    {shayaris.map((item, index) => {
                                        if (item.By === "Sophia") {
                                            return (
                                                <Card key={index} variant="outlined" sx={{ backgroundColor: alpha('#2E9900', 0.2), boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', width: 'fit-content', margin: '8px' }}>
                                                    <CardContent style={{ overflowWrap: 'break-word' }}>
                                                        {item.text.split('\\n').map(e => <Typography variant="h6" component="div">{e}</Typography>)}
                                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                            Added on {new Date(item.AddedOn.toDate()).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                                        </Typography>
                                                        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Button size="small" onClick={()=>handleLike(item.id)}>
                                                                <FavoriteIcon style={{ color: 'red', transform: 'scale(1.2)' }} />
                                                            </Button>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {item.Likes}
                                                            </Typography>
                                                            <Button size="small" onClick={()=>handleDelete(item.id)}>
                                                                <DeleteIcon style={{ transform: 'scale(1.2)' }} />
                                                            </Button>
                                                        </CardActions>
                                                    </CardContent>
                                                </Card>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Box>
                            </TableCell>
                            <TableCell align="center">
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                <Card variant="outlined" sx={{ backgroundColor: alpha('#c7c7c7', 0.4), boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', width: 'fit-content', margin: '8px' }}>
                                        <CardContent style={{ overflowWrap: 'break-word' }}>
                                            <form onSubmit={(event)=>addToList(event, "Varun")}>
                                                <textarea 
                                                    type="text"
                                                    placeholder="Shayari"
                                                    onChange={event => setNewShayariByVarun(event.target.value)}
                                                    value={newShayariByVarun}
                                                />
                                                <Button type="submit">Add</Button>
                                            </form>
                                        </CardContent>
                                    </Card>            
                                    {shayaris.map((item, index) => {
                                        if (item.By === "Varun") {
                                            return (
                                                <Card key={index} variant="outlined" sx={{ backgroundColor: alpha('#2E9900', 0.2), boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', width: 'fit-content', margin: '8px' }}>
                                                    <CardContent style={{ overflowWrap: 'break-word' }}>
                                                        {item.text.split('\\n').map(e => <Typography variant="h6" component="div">{e}</Typography>)}
                                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                            Added on {new Date(item.AddedOn.toDate()).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                                        </Typography>
                                                        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Button size="small" onClick={()=>handleLike(item.id)}>
                                                                <FavoriteIcon style={{ color: 'red', transform: 'scale(1.2)' }} />
                                                            </Button>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {item.Likes}
                                                            </Typography>
                                                            <Button size="small" onClick={()=>handleDelete(item.id)}>
                                                                <DeleteIcon style={{ transform: 'scale(1.2)' }} />
                                                            </Button>
                                                        </CardActions>
                                                    </CardContent>
                                                </Card>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress color="secondary" />
                </Box>
            )}
        </Fragment>
    )
}

export default Shayari;

import { auth } from "../../config/firebase";

const Memories = () => {
    return ( 
        <div>
            <h1>Memories</h1>
        </div>
        
     );
}
 
export default Memories;
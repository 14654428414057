import { RecoilRoot } from "recoil";
import Game from "./Game";
import './teddyday.css';

const TeddyDay = () => {
    return (
        <RecoilRoot>
            <Game />
        </RecoilRoot>
    );
}
 
export default TeddyDay;
import './album.css';
import { useState } from 'react';

export default function Album({ photos }) {
    return (
        <div className="gallery">
            {photos.map((item, index) => (
                <div className="pics" key={index} >
                    <img src={item.src} style={{width: '100%'}}/>
                </div>
            ))}
        </div>
    );
}
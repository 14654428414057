// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBFbsV08SVU1hvN8uqghX1vyM0IqQAGduQ",
  authDomain: "ldrportal.firebaseapp.com",
  projectId: "ldrportal",
  storageBucket: "ldrportal.appspot.com",
  messagingSenderId: "802269382885",
  appId: "1:802269382885:web:b724f4e88451c1b27d6c0c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const googleAuthProvider = new GoogleAuthProvider();
export const auth = getAuth(app);
export const db = getFirestore(app);

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar.js";
import { auth } from "./config/firebase";
import { useState } from "react";
import ChocolateDay from "./pages/chocolateday/ChocolateDay.js";
import BucketList from "./pages/bucketlist/BucketList.js";
import Home from "./pages/home/Home.js";
import Shayari from "./pages/shayari/Shayari.js";
import Memories from "./pages/memories/Memories.js";
import RoseDay from "./pages/roseday/RoseDay.js";
import ProposeDay from "./pages/proposeday/ProposeDay.js";
import TeddyDay from "./pages/teddyday/TeddyDay.js";
import Birthday from "./pages/birthday/Birthday.js";

function App() {
  // const [loggedInUser, setLoggedInUser] = useState(auth?.currentUser);
  const [loggedInUser, setLoggedInUser] = useState("varun");
  // auth.onAuthStateChanged((user) => {
  //     if (loggedInUser != user) setLoggedInUser(user);
  // });

  return (
    <Router>
      <Navbar />

      <Routes>
        {loggedInUser && (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/bucketlist" element={<BucketList />} />
            <Route path="/shayari" element={<Shayari />} />
            <Route path="/memories" element={<Memories />} />
            <Route path="/chocolateday" element={<ChocolateDay />} />
            <Route path="/roseday" element={<RoseDay />} />
            <Route path="/proposeday" element={<ProposeDay />} />
            <Route path="/teddyday" element={<TeddyDay />} />
            <Route path="/birthday" element={<Birthday />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;

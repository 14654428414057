import React, { useState, useEffect } from "react";
import "./Birthday.css";

const Birthday = () => {
  const targetDate = new Date("2024-11-13T00:00:00+05:30").getTime(); // India Time (IST)
  const [timeLeft, setTimeLeft] = useState(targetDate - new Date().getTime());
  const [showWish, setShowWish] = useState(false);
  const [showSurprise, setShowSurprise] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0) {
        clearInterval(timer);
        setTimeLeft(0);
        setShowWish(true);
      } else {
        setTimeLeft(distance);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const formatTime = (time) => {
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const handleSurprise = () => {
    setShowSurprise(true);
    const audio = new Audio(require("../../media/photos/birthday.m4a"));
    audio.play();
  };

  const initialPhoto = require(`../../media/photos/img3.jpeg`);
  const surprisePhoto = require(`../../media/photos/Sunjunki.jpg`);

  if (timeLeft > 0) {
    return (
      <div className="countdown-container">
        <h1>Countdown to Birthday:</h1>
        <h2>{formatTime(timeLeft)}</h2>
      </div>
    );
  }

  return (
    <div className="birthday-container">
      {showWish && !showSurprise && (
        <>
          <div className="birthday-message">
            <h1>Happy Birthday, Lovieeeee! 🎉❤️</h1>
            <p>
              Wishing you a day as wonderful as you are, filled with love, joy,
              and amazing moments!
            </p>
            <img src={initialPhoto} alt="Initial" className="birthday-photo" />
          </div>
          <button className="gift-button" onClick={handleSurprise}>
            🎁 Click to reveal your surprise!
          </button>
        </>
      )}

      {showSurprise && (
        <div className="birthday-message">
          <img src={surprisePhoto} alt="Surprise" className="birthday-photo" />
          <p>Hope you love your special surprise! 🎵❤️</p>
        </div>
      )}
    </div>
  );
};

export default Birthday;

import { getDocs, collection, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import { alpha } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { Timestamp } from 'firebase/firestore';

const BucketList = () => {
    const [newItemName, setNewItemName] = useState('');
    const bucketListCollectionRef = collection(db, "BucketList");
    
    const [bucketList, setBucketList] = useState([]);
    const [bucketListDone, setBucketListDone] = useState(false);

    const getBucketList = async () => {
        try {
            const data = (await getDocs(bucketListCollectionRef)).docs.map((doc) => ({...doc.data(), id: doc.id}));
            setBucketList(data);
            setBucketListDone(true);
        } catch (error) {
            console.log(error);
        }
    }

    const addToList = async (event) => {
        event.preventDefault();
        setNewItemName('');
        try {
            const existingItem = bucketList.find(item => item.Name.toLowerCase() === newItemName.toLowerCase());
            if (existingItem) {
                const updatedItem = {
                    ...existingItem,
                    DateAdded: Timestamp.now(),
                    IsTODO: true
                };
                await updateDoc(doc(bucketListCollectionRef, existingItem.id), updatedItem);
                setBucketList(bucketList.map(item => item.id === existingItem.id ? updatedItem : item));
            } else {
                const newItem = {
                    Name: newItemName,
                    DateAdded: Timestamp.now(),
                    AddedBy: "Varun",
                    IsTODO: true,
                    LastDoneOn: Timestamp.now(),
                    TimesDone: 0
                };

                const docRef = await addDoc(bucketListCollectionRef, newItem);
                const newItemWithId = { ...newItem, id: docRef.id };
                setBucketList([...bucketList, newItemWithId]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getBucketList();
    }, []);

    return (
        <Fragment>
            {bucketListDone ? (
                <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ width: '50%'}}>
                                <Typography variant="h6" component="div" style={{ fontSize: '1.5rem' }}>
                                    TODO
                                </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: '50%'}}>
                                <Typography variant="h6" component="div" style={{ fontSize: '1.5rem' }}>
                                    DONE
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    {/* Form for adding a new item */}
                                    <Card variant="outlined" sx={{ backgroundColor: alpha('#e90f65', 0.2), boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', width: 'fit-content', margin: '8px' }}>
                                        <CardContent style={{ overflowWrap: 'break-word' }}>
                                            <form onSubmit={(event)=>addToList(event)}>
                                                <input 
                                                    type="text"
                                                    placeholder="Name"
                                                    list="suggestions"
                                                    onChange={event => setNewItemName(event.target.value)}
                                                    value={newItemName}
                                                />
                                                <datalist id="suggestions">
                                                    {bucketList.map((item, index) => (
                                                        <option key={index} value={item.Name} />
                                                    ))}
                                                </datalist>
                                                <Button type="submit">Add</Button>
                                            </form>
                                        </CardContent>
                                    </Card>                                    
                                    {bucketList.map((item, index) => {
                                        if (item.IsTODO) {
                                            const handleDone = async () => {
                                                try {
                                                    // Update the item locally
                                                    const updatedItem = { ...item, IsTODO: false, TimesDone: item.TimesDone + 1, LastDoneOn: Timestamp.now() };
                                                    const updatedBucketList = [...bucketList];
                                                    updatedBucketList[index] = updatedItem;
                                                    setBucketList(updatedBucketList);

                                                    // Update the item in the database
                                                    await updateDoc(doc(db, "BucketList", item.id), {
                                                        IsTODO: false,
                                                        TimesDone: item.TimesDone + 1,
                                                        LastDoneOn: Timestamp.now(),
                                                    });
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            };

                                            const handleDelete = async () => {
                                                try {
                                                    // Update the item locally
                                                    const updatedItem = { ...item, IsTODO: false };
                                                    const updatedBucketList = [...bucketList];
                                                    updatedBucketList[index] = updatedItem;
                                                    setBucketList(updatedBucketList);

                                                    // Update the item in the database
                                                    await updateDoc(doc(db, "BucketList", item.id), {
                                                        IsTODO: false,
                                                    });
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            };

                                            return (
                                                <Card key={index} variant="outlined" sx={{ backgroundColor: alpha('#e90f65', 0.2), boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', width: 'fit-content', margin: '8px' }}>
                                                    <CardContent style={{ overflowWrap: 'break-word' }}>
                                                        <Typography variant="h6" component="div">
                                                            {item.Name}
                                                        </Typography>
                                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                            Added on {new Date(item.DateAdded.toDate()).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                                        </Typography>
                                                        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Button size="small" onClick={handleDone}>Done</Button>
                                                            <Button size="small" onClick={handleDelete}>Delete</Button>
                                                        </CardActions>
                                                    </CardContent>
                                                </Card>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Box>
                            </TableCell>
                            <TableCell align="center">
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    {bucketList.map((item, index) => {
                                        if (item.TimesDone > 0) {
                                            return (
                                                <Card key={index} variant="outlined" sx={{ backgroundColor: alpha('#2E9900', 0.2), boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', width: 'fit-content', margin: '8px' }}>
                                                    <CardContent style={{ overflowWrap: 'break-word' }}>
                                                        <Typography variant="h6" component="div">
                                                            {item.Name}
                                                        </Typography>
                                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                            Last done on {new Date(item.LastDoneOn.toDate()).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Times done: {item.TimesDone}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress color="secondary" />
                </Box>
            )}
        </Fragment>
    )
}

export default BucketList;

import { Link } from "react-router-dom";
import SignOut from "../../views/SignOut";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
import { alpha } from "@mui/material/styles";
import { googleAuthProvider, auth } from "../../config/firebase";
import { signInWithPopup } from "firebase/auth";
import "./navbar.css";

const pages = [
  // TODO: Uncomment pages
  { text: "Home", href: "/" },
  { text: "Chocolate Day", href: "/chocolateday" },
  { text: "Rose Day", href: "/roseday" },
  { text: "Propose Day", href: "/proposeday" },
  { text: "Teddy Day", href: "/teddyday" },
  { text: "Bucket List", href: "/bucketlist" },
  { text: "Birthday", href: "/birthday" },
  // { text: 'Shayari', href: '/shayari' },
  // { text: 'Memories', href: '/memories' },
];

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  // TODO
  // const [loggedInUser, setLoggedInUser] = useState(auth?.currentUser);
  const [loggedInUser, setLoggedInUser] = useState("varun");
  // auth.onAuthStateChanged((user) => {
  //     if (loggedInUser != user) setLoggedInUser(user);
  // });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (to) => {
    navigate(to);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogin = () => {
    signInWithPopup(auth, googleAuthProvider);
    setLoggedInUser(auth?.currentUser);
  };

  const handleLogout = () => {
    auth.signOut();
    handleCloseUserMenu();
  };

  const settings = [{ text: "Logout", function: handleLogout }];

  return (
    <AppBar position="static" sx={{ backgroundColor: alpha("#e90f65", 0.8) }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <FavoriteRoundedIcon
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {/* LOVIE */}
            VALENTINE WEEK
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {loggedInUser && (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu(0)}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.text}
                  onClick={() => handleCloseNavMenu(page.href)}
                >
                  <Typography textAlign="center">{page.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <FavoriteRoundedIcon
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {/* LOVIE */}
            VALENTINE WEEK
          </Typography>
          {loggedInUser && (
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.text}
                  onClick={() => handleCloseNavMenu(page.href)}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page.text}
                </Button>
              ))}
            </Box>
          )}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              {/* TODO: login user */}
              {/* {loggedInUser == null ? (
                                    <div className="login-container" style={{ width: '100%', height: '100%', padding: '10px' }}>
                                        <button type="button" className="google-sign-in-button" onClick={handleLogin}>
                                            Sign in with Google
                                        </button>
                                    </div>
                                ) : (
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt={loggedInUser.displayName} src="/static/images/avatar/2.jpg" />
                                    </IconButton>
                                )} */}
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.text} onClick={setting.function}>
                  <Typography textAlign="center">{setting.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
